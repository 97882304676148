import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import PageTitle from '../components/page_title'
import PageContents from '../components/page_contents'
import * as style from '../styles/services.module.css'
import ScaleIcon from '../images/svg/scale-icon.svg'
import LicenseIcon from '../images/svg/license-icon.svg'

export default function Services() {
  return (
    <Layout showFooter={true}>
      <Seo
        title='Services'
        description='The Linden Law Group specializes in litigation and liquor licensing cases. Click here to learn how we can help you.'
      />

      <PageContents>
        <PageTitle>What We Do</PageTitle>

        <div className={style.main}>
          <div
            className={`${style.service} wide_content`}
            style={{ marginBottom: '0.5rem' }}
          >
            <div className={style.left}>
              <span className={style.name}>Litigation</span>
              <ScaleIcon className={style.icon} />
            </div>

            <span className={style.text}>
              Our very active litigation practice involves civil actions
              commenced in the state and federal district courts of Colorado.
              Since the formation of our firm in 2005, our two-attorney
              litigation team has handled well over a hundred filed
              court-actions. We have developed a litigation specialty of
              pursuing actions for fraud, misrepresentation and/or breach of
              fiduciary duty causing financial harm to our clients. We also
              regularly represent commercial and individual clients defending
              civil allegations of negligence, breach of contract, violation of
              statutory duties, and other matters. If it's civil litigation, we
              are interested.
            </span>

            <Link to='/results' className={style.link}>
              Our Success
            </Link>
          </div>

          <div className={`${style.service} wide_content`}>
            <div className={style.left}>
              <span className={style.name}>Liquor Licensing</span>
              <LicenseIcon className={style.icon} />
            </div>

            <span className={style.text}>
              We have developed a liquor licensing practice with a long and
              successful track record of defending, procuring, and transferring
              all types of liquor licenses in Colorado Springs and various
              localities throughout Colorado. We are very proud of the fact that
              most of the "homegrown" small business liquor licensees operating
              in El Paso County are clients of our firm. Having successfully
              handled all types of liquor licensing matters, and hearings before
              boards and tribunals throughout Colorado, we understand the value
              of your liquor license - and how to protect it.
            </span>

            <Link to='/results' className={style.link}>
              Our Success
            </Link>
          </div>
        </div>
      </PageContents>
    </Layout>
  )
}
